import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Layout from "./Layout";
import GtmScript from "../src/Utility/GtmScript";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));
const ACA_SPH_V11A = lazy(() => import("../src/Components/Pages/Splits/ACA_SPH_V11A"));
const MedicareThankYou = lazy(() => import("../src/Components/Pages/Thankyou/MedicareThankYou"));
const GreencardThankyou = lazy(() => import("../src/Components/Pages/Thankyou/GreencardThankyou"));
const PrivacyPolicy = lazy(() => import("../src/Components/Pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("../src/Components/Pages/TermsOfUse"));
const CookiesPolicy = lazy(() => import("../src/Components/Pages/CookiesPolicy"));
const RedirectWithQueryParams = lazy(() => import("./Utility/RedirectWithQueryParams"));
const split_SGA_LP1 = lazy(() => import("../src/Components/Pages/Splits/split_SGA_LP1"));
const YourDetails = lazy(() => import("../src/Components/Pages/SGA_LP1/YourDetails"));
const Whychoose = lazy(() => import("../src/Components/Pages/SGA_LP1/Whychoose"));
const SelectState = lazy(() => import("../src/Components/Pages/SGA_LP1/SelectState"));
const Thankyou = lazy(() => import("../src/Components/Pages/SGA_LP1/Thankyou"));
const ReviewSummary = lazy(() => import("../src/Components/Pages/SGA_LP1/ReviewSummary"));
const Questionnaire = lazy(() => import("../src/Components/Pages/SGA_LP1/Questionnaire"));
const Privacy = lazy(() => import("../src/Components/Pages/SGA_LP1/Privacy"));
const TermsOfCondition = lazy(() => import("../src/Components/Pages/SGA_LP1/TermsOfCondition"));
const SpouseDetails = lazy(() => import("../src/Components/Pages/SGA_LP1/SpouseDetails"));
const Terms = lazy(() => import("../src/Components/Pages/SGA_LP1/Terms"));
const FbPixel = lazy(() => import("../src/Components/Pages/FbPixel"));
const HelmetBasedOnDomain = lazy(() => import("./Components/Includes/Layouts/common/HelmetBasedOnDomain"));
const DoctorDetails = lazy(() => import("./Components/Pages/SGA_LP1/DoctorDetails"));

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  return (
    <>
    <HelmetBasedOnDomain />
    <VisitorParamsProvider value={{ visitorParameters }}>
      <RedirectProvider value={{ redirectUrl }}>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Switch>
              {/* <Layout> */}
                <Route exact path="/" component={HomePage} />
                <Route exact path="/ACA_SPH_V11A" component={ACA_SPH_V11A} />
                <Route exact path={"/medicare-thankyou"} component={MedicareThankYou} />
                <Route exact path={"/greencard-thankyou"} component={GreencardThankyou} />
                <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
                <Route exact path={"/terms-of-use"} component={TermsOfUse} />
                <Route exact path={"/terms"} component={Terms} />
                <Route exact path={"/cookies-policy"} component={CookiesPolicy} />
                <Route exact path="/SGA_LP1" component={split_SGA_LP1} />
                <Route exact path="/your-details" component={YourDetails} />
                <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
                <Route exact path="/whychoose" component={Whychoose} />
                <Route exact path="/select-state" component={SelectState} />
                <Route exact path={["/thankyou"]} component={Thankyou}/>
                <Route exact path={["/review-summary"]} component={ReviewSummary}/>
                <Route exact path={["/questionnaire"]} component={Questionnaire}/>
                <Route exact path={"/privacy"} component={Privacy} />
                <Route exact path={"/terms-of-condition"} component={TermsOfCondition} />
                <Route exact path={"/spouse-details"} component={SpouseDetails} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={"/doctor-details"} component={DoctorDetails}/>
                <Route path="*"><RedirectWithQueryParams/></Route>              

              {/* </Layout> */}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </RedirectProvider>
    </VisitorParamsProvider>
    <GtmScript/>
    </>
  );
};

export default App;
